import styled from '@emotion/styled/macro';
import React from 'react';
import { FontWeight } from 'styled-typography';
import { Typography, TypographyProps } from '../../common/Typography';

export const InformationGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`;

const InformationGridRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export type InformationGridProps = {
  title: string;
  rows: Array<{
    classNamePrefix: string;
    label: string;
    value: string;
    labelTypographyProps?: Partial<TypographyProps>;
    valueTypographyProps?: Partial<TypographyProps>;
  }>;
};

export function InformationGrid({ title, rows }: InformationGridProps) {
  return (
    <InformationGridContainer>
      <Typography variant="h6" color="secondary" fontWeight={FontWeight.Medium}>
        {title}
      </Typography>
      {rows.map(({ classNamePrefix, label, value, labelTypographyProps, valueTypographyProps }) => (
        <InformationGridRow key={label}>
          <Typography
            className={`${classNamePrefix}-label`}
            variant="body1"
            color="secondary"
            style={{ width: 80 }}
            {...labelTypographyProps}>
            {label}
          </Typography>
          <Typography className={`${classNamePrefix}-text`} variant="body1" color="secondary" {...valueTypographyProps}>
            {value}
          </Typography>
        </InformationGridRow>
      ))}
    </InformationGridContainer>
  );
}
