import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { BillableFeatureInput } from '@stigg/api-client-js/src/generated/sdk';
import { BillingModel, BillingPeriod, Plan, Price } from '@stigg/js-client-sdk';

import { Typography } from '../../../common/Typography';
import { useChargesSort } from '../../../hooks/useChargeSort';
import { calculateUnitQuantityText } from '../../../paywall/utils/calculateUnitQuantityText';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { InputField } from '../../components';
import { useCheckoutModel, usePlanStepModel, useProgressBarModel } from '../../hooks';
import { TiersSelectContainer } from '../../../common/TiersSelectContainer';
import { getValidPriceQuantity } from '../../../utils/priceUtils';
import { getFeatureDisplayNameText } from '../../../utils/getFeatureName';
import { ON_WHEEL_BLUR } from '../../../utils/onWheelBlur';
import { mq } from '../../../common/mediaQuery';
import { useIsScreenWiderThan } from '../../../hooks/useIsScreenWiderThan';

export type UsePlanStepModel = ReturnType<typeof usePlanStepModel>;

type CheckoutChargeListProps = {
  plan?: Plan;
  billingPeriod: BillingPeriod;
};

const StyledPlanCharge = styled.div`
  display: flex;
  min-height: 60px;
  margin-top: 16px;
  flex-direction: column;

  ${mq.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const getValidationText = (charge: Price, quantity?: number) => {
  const { minUnitQuantity, maxUnitQuantity } = charge;
  if (!quantity || quantity < (minUnitQuantity || 1)) {
    return `Minimum ${minUnitQuantity || 1}`;
  }

  if (maxUnitQuantity && quantity > maxUnitQuantity) {
    return `Maximum ${maxUnitQuantity}`;
  }

  return '';
};

export function PlanCharge({
  charge,
  isValid,
  setBillableFeature,
  billableFeature,
  onValidationChange,
}: {
  charge: Price;
  isValid: boolean;
  billableFeature?: BillableFeatureInput;
  setBillableFeature: UsePlanStepModel['setBillableFeature'];
  onValidationChange: ({ featureId, isValid }: { featureId: string; isValid: boolean }) => void;
}) {
  const isScreenWiderThanMd = useIsScreenWiderThan('md');
  const featureId = charge.feature?.featureId;
  const isBaseCharge = !featureId;
  const isPayAsYouGo = charge.pricingModel === BillingModel.UsageBased;
  const displayName = isBaseCharge
    ? 'Base charge'
    : getFeatureDisplayNameText(charge.feature?.displayName || '', charge.feature?.units, charge.feature?.unitsPlural);
  const hasQuantityRestrictions = !!(charge?.minUnitQuantity || charge?.maxUnitQuantity);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isBaseCharge || !featureId) return;

    const { minUnitQuantity, maxUnitQuantity } = charge;
    const value = event?.target?.value ? Number(event?.target?.value) : null;
    if (
      !value ||
      value <= 0 ||
      (minUnitQuantity && value < minUnitQuantity) ||
      (maxUnitQuantity && value > maxUnitQuantity)
    ) {
      onValidationChange({ featureId, isValid: false });
      // Reset the input value to null
      // @ts-ignore
      setBillableFeature(featureId, value ?? null);
      return;
    }

    onValidationChange({ featureId, isValid: true });
    const quantity = getValidPriceQuantity(charge, value);
    setBillableFeature(featureId, quantity);
  };

  let chargeRow;

  if (isBaseCharge || isPayAsYouGo) {
    const formattedAmount = currencyPriceFormatter({
      amount: charge.amount!,
      currency: charge.currency,
      locale: 'en-us',
      minimumFractionDigits: 2,
    });

    chargeRow = `${formattedAmount}`;
    if (isPayAsYouGo) {
      chargeRow += ' / unit';
    }
  } else if (charge.isTieredPrice && charge.tiersMode && featureId) {
    const quantity = billableFeature?.quantity || 1;
    chargeRow = (
      <TiersSelectContainer
        componentId={`${featureId}-tiers`}
        tiers={charge.tiers}
        tiersMode={charge.tiersMode}
        value={quantity}
        handleChange={(quantity: number) => {
          setBillableFeature(featureId, quantity);
        }}
        width={120}
      />
    );
  } else {
    chargeRow = (
      <InputField
        sx={{ width: isScreenWiderThanMd ? 120 : '100%' }}
        id={`${featureId}-input`}
        type="number"
        onWheel={ON_WHEEL_BLUR}
        error={!isValid}
        helperText={!isValid ? getValidationText(charge, billableFeature?.quantity) : undefined}
        FormHelperTextProps={{ sx: { margin: '4px' } }}
        value={billableFeature?.quantity ?? ''}
        onChange={handleQuantityChange}
      />
    );
  }

  return (
    <StyledPlanCharge>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" color="primary" lineHeight="24px">
          {displayName}
        </Typography>
        {hasQuantityRestrictions && (
          <Typography variant="body1" color="secondary" lineHeight="20px">
            {calculateUnitQuantityText(charge.minUnitQuantity, charge.maxUnitQuantity, charge.feature?.unitsPlural)}
          </Typography>
        )}
      </Box>

      <Typography variant="h6" color="primary">
        {chargeRow}
      </Typography>
    </StyledPlanCharge>
  );
}

export function CheckoutChargeList({ plan, billingPeriod }: CheckoutChargeListProps) {
  const { billableFeatures, setBillableFeature } = usePlanStepModel();
  const { setIsDisabled } = useProgressBarModel();
  const { setIsValid } = useCheckoutModel();
  const planCharges = useChargesSort(plan?.pricePoints?.filter((p) => p.billingPeriod === billingPeriod) || []);
  const [chargesValidation, setChargesValidation] = useState(
    planCharges?.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr.feature?.featureId || 'base-charge'] = true;
      return acc;
    }, {}),
  );

  useEffect(() => {
    const isDisabled = Object.values(chargesValidation).some((x) => !x);
    setIsDisabled(isDisabled);
    setIsValid(!isDisabled);
  }, [chargesValidation, setIsDisabled, setIsValid]);

  return (
    <>
      {planCharges?.map((charge) => {
        const billableFeature = billableFeatures.find((x) => x.featureId === charge.feature?.featureId);
        return (
          <PlanCharge
            key={charge.feature?.featureId || 'base-charge'}
            charge={charge}
            setBillableFeature={setBillableFeature}
            billableFeature={billableFeature}
            isValid={chargesValidation[charge.feature?.featureId || 'base-charge']}
            onValidationChange={({ featureId, isValid }: { featureId: string; isValid: boolean }) =>
              setChargesValidation((prev) => ({ ...prev, [featureId]: isValid }))
            }
          />
        );
      })}
    </>
  );
}
