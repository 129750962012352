import { BillableFeatureInput } from '@stigg/api-client-js/src/generated/sdk';
import { Addon, BillableFeature, BillingPeriod, CheckoutStatePlan } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';
import { CheckoutSteps } from '../configurations/steps';
import { hasMultipleBillingPeriods } from '../../utils/planPrices';

export enum CheckoutStepKey {
  PLAN = 'PLAN',
  ADDONS = 'ADDONS',
  PAYMENT = 'PAYMENT',
}

export type CheckoutStep = {
  key: CheckoutStepKey;
  label: string;
};

const CHECKOUT_STEPS: CheckoutStep[] = [
  { key: CheckoutStepKey.PLAN, label: 'Plan' },
  { key: CheckoutStepKey.ADDONS, label: 'Add-ons' },
  { key: CheckoutStepKey.PAYMENT, label: 'Payment details' },
];

export type ProgressBarState = {
  activeStep: number;
  completedSteps: number[];
  steps: CheckoutStep[];
  isDisabled: boolean;
};

const INITIAL_STATE: ProgressBarState = {
  activeStep: 0,
  completedSteps: [],
  steps: CHECKOUT_STEPS,
  isDisabled: false,
};

type GetProgressBarInitialStateProps = {
  isLoading: boolean;
  skipCheckoutSteps: CheckoutSteps[];
  plan?: CheckoutStatePlan;
  availableAddons?: Addon[];
  availableCharges: BillableFeatureInput[];
  preferredBillingPeriod?: BillingPeriod;
  preconfiguredBillableFeatures: BillableFeature[];
};

const canSkipPlanStep = (
  plan: CheckoutStatePlan,
  availableCharges: BillableFeatureInput[],
  preconfiguredBillableFeatures: BillableFeature[],
  preferredBillingPeriod?: BillingPeriod,
) => {
  // if multiple billing periods are available, a preferred billing period must be selected
  if (hasMultipleBillingPeriods(plan) && !preferredBillingPeriod) {
    return false;
  }

  const availableChargesIds = availableCharges.map((charge) => charge.featureId);
  const preconfiguredChargesIds = preconfiguredBillableFeatures.map((charge) => charge.featureId);

  // if there are available charges, they must be preconfigured
  if (
    availableChargesIds.length !== preconfiguredChargesIds.length ||
    !availableChargesIds.every((charge) => preconfiguredChargesIds.includes(charge))
  ) {
    return false;
  }

  return true;
};

export function getProgressBarInitialState({
  isLoading,
  skipCheckoutSteps,
  plan,
  availableAddons,
  availableCharges,
  preferredBillingPeriod,
  preconfiguredBillableFeatures,
}: GetProgressBarInitialStateProps) {
  if (isLoading || !plan) {
    return INITIAL_STATE;
  }

  const stepsToFilter: CheckoutStepKey[] = [];

  if (
    skipCheckoutSteps.includes('PLAN') &&
    canSkipPlanStep(plan, availableCharges, preconfiguredBillableFeatures, preferredBillingPeriod)
  ) {
    stepsToFilter.push(CheckoutStepKey.PLAN);
  }

  if (availableAddons?.length === 0 || skipCheckoutSteps.includes('ADDONS')) {
    stepsToFilter.push(CheckoutStepKey.ADDONS);
  }

  return { ...INITIAL_STATE, steps: CHECKOUT_STEPS.filter((step) => !stepsToFilter.includes(step.key)) };
}

function useProgressBarState() {
  const [{ progressBar }] = useCheckoutContext();
  return progressBar;
}

function useSetActiveStep() {
  const [, setState] = useCheckoutContext();
  return (stepNumber: number) =>
    setState(({ progressBar }) => {
      progressBar.activeStep = stepNumber;
    });
}
function useMarkStepAsCompleted() {
  const [, setState] = useCheckoutContext();
  return (stepNumber: number) =>
    setState(({ progressBar }) => {
      progressBar.completedSteps.push(stepNumber);
    });
}

function isCheckoutComplete(progressBar: ProgressBarState) {
  return progressBar.completedSteps.length >= progressBar.steps.length - 1;
}

function useGoNext() {
  const [, setState] = useCheckoutContext();
  return () =>
    setState(({ progressBar }) => {
      if (progressBar.isDisabled) {
        return;
      }

      if (!progressBar.completedSteps.includes(progressBar.activeStep)) {
        progressBar.completedSteps.push(progressBar.activeStep);
      }

      if (progressBar.activeStep < progressBar.steps.length - 1) {
        progressBar.activeStep += 1;
      }
    });
}

function useSetIsDisabled() {
  const [, setState] = useCheckoutContext();
  return (isDisabled?: boolean) =>
    setState(({ progressBar }) => {
      progressBar.isDisabled = !!isDisabled;
    });
}

export function useProgressBarModel() {
  const progressBarState = useProgressBarState();
  const currentStep = progressBarState.steps[progressBarState.activeStep];

  return {
    currentStep,
    progressBarState,
    isLastStep: progressBarState.activeStep === progressBarState.steps.length - 1,
    isCheckoutComplete: isCheckoutComplete(progressBarState),
    setActiveStep: useSetActiveStep(),
    markStepAsCompleted: useMarkStepAsCompleted(),
    goNext: useGoNext(),
    setIsDisabled: useSetIsDisabled(),
  };
}
