import { Plan } from '@stigg/js-client-sdk';
import { BillingPeriod } from '@stigg/api-client-js/src/generated/sdk';

export const hasMonthlyPrices = (plan?: Plan) =>
  !!plan?.pricePoints.some((pricePoint) => pricePoint.billingPeriod === BillingPeriod.Monthly);

export const hasAnnualPrices = (plan?: Plan) =>
  !!plan?.pricePoints.some((pricePoint) => pricePoint.billingPeriod === BillingPeriod.Annually);

export const hasMultipleBillingPeriods = (plan?: Plan) => hasMonthlyPrices(plan) && hasAnnualPrices(plan);
