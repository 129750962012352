import styled from '@emotion/styled/macro';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useIsScreenWiderThan } from '../../hooks/useIsScreenWiderThan';

const SkeletonLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SkeletonLayoutLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function SubscriptionOverviewLoader() {
  const isScreenWiderThanMd = useIsScreenWiderThan('md');
  return (
    <SkeletonLayout className="stigg-subscription-overview-skeleton-layout">
      <SkeletonLayoutLeft>
        <Skeleton width={120} />
        <Skeleton width={120} />
      </SkeletonLayoutLeft>
      <Skeleton width={isScreenWiderThanMd ? 295 : 260} height={193} />
    </SkeletonLayout>
  );
}
