import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import { mq } from '../common/mediaQuery';

export const CheckoutLayout = styled.div`
  margin: auto;
  min-height: 760px;
  max-width: 920px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.stigg.palette.backgroundPaper};
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};

  & * {
    box-sizing: border-box;
  }

  padding: 16px 16px;
  ${mq.md} {
    padding: 32px;
    width: calc(100% - 64px);
    border-radius: 10px;
  }
`;
export const CheckoutContent = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  flex-wrap: wrap;
  width: 100%;

  flex-direction: column;
  ${mq.md} {
    flex-direction: row;
  }
`;

export const CheckoutPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 2;

  width: 100%;
  ${mq.md} {
    width: auto;
  }
`;
