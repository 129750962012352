import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';
import {
  BillingModel,
  Price,
  SubscriptionPreviewInvoice,
  SubscriptionPreviewV2,
  TiersMode,
} from '@stigg/js-client-sdk';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Link from '@mui/material/Link';
import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { Typography } from '../../../common/Typography';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { WithSkeleton } from './WithSkeleton';
import { Skeleton } from '../../components/Skeletons.style';
import { CheckoutLocalization } from '../../configurations/textOverrides';
import { Icon } from '../../../common/Icon';
import { InformationTooltip } from '../../../common/InformationTooltip';
import { getPriceBreakdownString } from './getPriceBreakdownString';
import { GraduatedPriceBreakdown } from './GraduatedPriceBreakdown';
import { CollapsableSectionIcon } from '../../../common/CollapsableSectionIcon';
import { calculateTierPrice } from '../../../utils/priceTierUtils';
import { useIsScreenWiderThan } from '../../../hooks/useIsScreenWiderThan';
import { mq } from '../../../common/mediaQuery';

export const LineItemContainer = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const NestedBreakdownContainer = styled.div`
  margin-top: 16px;
  margin-left: 16px;
`;

export const LineItemRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mq.lg} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const PayAsYouGoPriceTooltip = ({ checkoutLocalization }: { checkoutLocalization: CheckoutLocalization }) => {
  const title = <Typography variant="body1">{checkoutLocalization.summary.payAsYouGoTooltipText}</Typography>;
  return (
    <InformationTooltip placement="left" title={title}>
      <Icon icon="PayAsYouGoCharge" style={{ display: 'flex' }} />
    </InformationTooltip>
  );
};

export const BilledPriceLineItem = ({
  checkoutLocalization,
  label,
  quantity,
  price,
}: {
  checkoutLocalization: CheckoutLocalization;
  label: string;
  quantity: number;
  price: Price;
}) => {
  const isScreenWiderThanLg = useIsScreenWiderThan('lg');
  const [isNestedBreakdownOpen, setIsNestedBreakdownOpen] = useState(false);
  const toggleNestedBreakdown = () => setIsNestedBreakdownOpen((prev) => !prev);

  const isPayAsYouGo = price.pricingModel === BillingModel.UsageBased;
  const totalAmount = price.isTieredPrice
    ? calculateTierPrice(price, quantity)
    : (price.amount || 0) * Math.ceil(quantity / (price.blockSize || 1));

  let nestedBreakdown: ReactNode;
  const shouldShowGraduatedPriceBreakdown =
    price.tiersMode === TiersMode.Graduated &&
    !!price.tiers &&
    !isEmpty(price.tiers) &&
    !isNil(price.tiers[0].upTo) &&
    quantity > price.tiers[0].upTo;

  if (shouldShowGraduatedPriceBreakdown) {
    nestedBreakdown = <GraduatedPriceBreakdown price={price} unitQuantity={quantity} />;
  }

  let title: ReactNode = (
    <Typography variant="body1" color="secondary">
      {label}
    </Typography>
  );

  if (nestedBreakdown) {
    title = (
      <Link onClick={toggleNestedBreakdown} underline="none" style={{ cursor: 'pointer' }}>
        {title}
      </Link>
    );
  }

  return (
    <LineItemContainer className="stigg-checkout-summary-base-charges-container">
      <LineItemRow style={{ alignItems: 'flex-start' }}>
        <Grid item display="flex" gap={0.5} style={{ whiteSpace: isScreenWiderThanLg ? 'nowrap' : 'break-spaces' }}>
          {title}
          {nestedBreakdown && (
            <IconButton onClick={toggleNestedBreakdown} sx={{ padding: 0 }}>
              <CollapsableSectionIcon $isOpen={isNestedBreakdownOpen} $size={16} />
            </IconButton>
          )}
        </Grid>
        <Grid item display="flex" gap={1} alignItems="center">
          {isPayAsYouGo && <PayAsYouGoPriceTooltip checkoutLocalization={checkoutLocalization} />}
          <Typography
            variant="body1"
            color="secondary"
            style={{ whiteSpace: isScreenWiderThanLg ? 'nowrap' : 'break-spaces' }}>
            {getPriceBreakdownString({
              totalAmount,
              quantity,
              currency: price.currency,
              pricingModel: price.pricingModel,
              billingPeriod: price.billingPeriod,
              tiers: price.tiers,
              tiersMode: price.tiersMode,
              blockSize: price.blockSize,
              priceAmount: price.amount,
            })}
            {isPayAsYouGo && ' / unit'}
          </Typography>
        </Grid>
      </LineItemRow>
      {nestedBreakdown && (
        <Collapse in={isNestedBreakdownOpen}>
          <NestedBreakdownContainer>{nestedBreakdown}</NestedBreakdownContainer>
        </Collapse>
      )}
    </LineItemContainer>
  );
};

export const FreeChargeLineItem = ({ label }: { label: string }) => {
  return (
    <LineItemContainer>
      <LineItemRow style={{ alignItems: 'flex-end' }}>
        <Grid item>
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
        </Grid>
        <Grid item display="flex" gap={1} alignItems="center">
          <Typography variant="body1" color="secondary" style={{ wordBreak: 'break-word' }}>
            Free
          </Typography>
        </Grid>
      </LineItemRow>
    </LineItemContainer>
  );
};

export const DiscountLineItem = ({
  subscriptionPreview,
  isFetchingSubscriptionPreview,
  checkoutLocalization,
}: {
  subscriptionPreview: SubscriptionPreviewV2;
  isFetchingSubscriptionPreview: boolean;
  checkoutLocalization: CheckoutLocalization;
}) => {
  const { immediateInvoice, recurringInvoice } = subscriptionPreview;
  const { discount, discountDetails } = recurringInvoice || {};
  if (!discount || !discountDetails) {
    return null;
  }

  return (
    <LineItemContainer>
      <LineItemRow>
        <Typography variant="body1" color="secondary">
          {checkoutLocalization.summary.discountText({
            discountDetails,
            currency: immediateInvoice.total.currency,
          })}
        </Typography>
        <Typography variant="body1" color="secondary">
          {isFetchingSubscriptionPreview ? (
            <Skeleton width={50} height={16} />
          ) : (
            currencyPriceFormatter({
              amount: discount.amount,
              currency: discount.currency,
              minimumFractionDigits: 2,
            })
          )}
        </Typography>
      </LineItemRow>
    </LineItemContainer>
  );
};

export const AppliedCreditsLineItem = ({
  subscriptionPreview,
  isFetchingSubscriptionPreview,
  checkoutLocalization,
}: {
  subscriptionPreview: SubscriptionPreviewV2 | null;
  isFetchingSubscriptionPreview: boolean;
  checkoutLocalization: CheckoutLocalization;
}) => {
  const { immediateInvoice } = subscriptionPreview || {};
  const { credits } = immediateInvoice || {};

  if (!credits || !credits.used || credits.used.amount <= 0) {
    return null;
  }

  return (
    <LineItemContainer>
      <LineItemRow>
        <Typography variant="body1" color="secondary">
          {checkoutLocalization.summary.appliedCreditsTitle}
        </Typography>
        <Typography variant="body1" color="secondary">
          <WithSkeleton isLoading={isFetchingSubscriptionPreview}>
            {currencyPriceFormatter({
              amount: -1 * credits.used.amount,
              currency: credits.used.currency,
              minimumFractionDigits: 2,
            })}
          </WithSkeleton>
        </Typography>
      </LineItemRow>
    </LineItemContainer>
  );
};

export const TaxLineItem = ({
  tax,
  taxDetails,
  isFetchingSubscriptionPreview,
  checkoutLocalization,
}: {
  isFetchingSubscriptionPreview: boolean;
  checkoutLocalization: CheckoutLocalization;
} & Pick<SubscriptionPreviewInvoice, 'tax' | 'taxDetails'>) => {
  if (!taxDetails || !tax || tax?.amount <= 0) {
    return null;
  }

  return (
    <LineItemContainer>
      <LineItemRow>
        <Typography variant="body1" color="secondary">
          {checkoutLocalization.summary.taxTitle({ taxDetails })}
        </Typography>
        <Typography variant="body1" color="secondary">
          <WithSkeleton isLoading={isFetchingSubscriptionPreview}>
            {currencyPriceFormatter({ amount: tax?.amount, currency: tax?.currency, minimumFractionDigits: 2 })}
          </WithSkeleton>
        </Typography>
      </LineItemRow>
    </LineItemContainer>
  );
};
