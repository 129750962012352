import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StepIcon from '@mui/material/StepIcon';
import { useCheckoutModel, useProgressBarModel } from '../hooks';
import { Typography } from '../../common/Typography';
import { StyledIcon, StyledProgress, StyledStepButton } from './CheckoutProgressBar.style';
import { Icons } from '../../common/Icon';
import { Skeleton } from '../components/Skeletons.style';
import { useIsScreenWiderThan } from '../../hooks/useIsScreenWiderThan';

export const CheckoutProgressBar = () => {
  const { progressBarState, setActiveStep, currentStep } = useProgressBarModel();
  const { widgetState } = useCheckoutModel();
  const isScreenWiderThanMd = useIsScreenWiderThan('md');
  const containerRef = useRef<HTMLElement>();
  const { readOnly, isLoadingCheckoutData } = widgetState;
  const { activeStep, completedSteps, steps } = progressBarState || {};
  const progress = ((activeStep + 1) * 100) / steps.length;

  const [previousStep, setPreviousStep] = React.useState(currentStep);
  useEffect(() => {
    if (!isScreenWiderThanMd) {
      if (containerRef.current && currentStep !== previousStep) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setPreviousStep(currentStep);
  }, [currentStep, isScreenWiderThanMd, previousStep, setPreviousStep]);

  if (progressBarState.steps.length === 1) {
    return null;
  }

  return (
    <Box className="stigg-checkout-progress-container" sx={{ width: '100%', mb: 3 }} ref={containerRef}>
      <StyledProgress variant="determinate" value={progress} $disabled={readOnly} />
      <Grid container display="flex">
        {steps.map(({ key, label }, index) => {
          const isCompleted = completedSteps.includes(index);
          const isDisabled =
            readOnly ||
            (index > activeStep && !isCompleted && !completedSteps.includes(index - 1)) ||
            (activeStep !== index && progressBarState.isDisabled);
          const checkedIcon: Icons = isDisabled ? 'OutlinedCheckedCircleDisabled' : 'OutlinedCheckedCircle';

          return (
            <Grid
              key={key}
              item
              display="flex"
              flexDirection="row"
              flex={isScreenWiderThanMd ? 1 : 'auto'}
              justifyContent="flex-start">
              {isLoadingCheckoutData ? (
                <Skeleton width={120} height={20} style={{ marginTop: 8 }} />
              ) : (
                <StyledStepButton onClick={() => setActiveStep(index)} fullWidth disabled={isDisabled}>
                  <Grid item display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <StepIcon
                      icon={
                        <StyledIcon
                          icon={isCompleted ? checkedIcon : 'OutlinedCircle'}
                          $disabled={isDisabled}
                          $shouldFill={isCompleted}
                        />
                      }
                    />

                    <Typography variant="h6" color={isDisabled ? 'primary.main.light' : 'primary.main'}>
                      {label}
                    </Typography>
                  </Grid>
                </StyledStepButton>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
