import styled from '@emotion/styled/macro';
import { mq } from '../../common/mediaQuery';

export const SectionContainer = styled.div<{ $backgroundColor: string; $borderColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};

  padding: 16px;
  ${mq.sm} {
    padding: 32px;
  }
  ${mq.md} {
    padding: 48px;
  }
  ${mq.lg} {
    padding: 64px;
  }
`;
