import React from 'react';

import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import { Customer } from '@stigg/js-client-sdk';

import { Typography } from '../../../common/Typography';
import {
  NewPaymentMethodContainer,
  PaymentMethodContainer,
  PaymentMethodLayoutContainer,
  PaymentMethodTextContainer,
} from './PaymentMethods.style';
import { StripePaymentForm } from './stripe';
import { CheckoutLocalization } from '../../configurations/textOverrides';
import { CheckoutContainerProps } from '../../CheckoutContainer';

export type PaymentMethodLayoutProps = {
  checked: boolean;
  text: React.ReactNode;
  subtitle?: React.ReactNode;
  readOnly?: boolean;
};

export type PaymentMethodProps = Pick<Customer, 'paymentMethodDetails'> &
  Pick<PaymentMethodLayoutProps, 'checked' | 'readOnly'> & { onSelect: () => void };

export type NewPaymentMethodProps = Pick<PaymentMethodLayoutProps, 'checked' | 'readOnly'> & {
  onSelect: () => void;
  checkoutLocalization: CheckoutLocalization;
  hasExistingPaymentMethod: boolean;
} & Pick<CheckoutContainerProps, 'onBillingAddressChange' | 'collectPhoneNumber'>;

function PaymentMethodLayout({ checked, text, subtitle, readOnly }: PaymentMethodLayoutProps) {
  return (
    <PaymentMethodLayoutContainer>
      <Radio checked={checked} disabled={readOnly} />
      <PaymentMethodTextContainer container>
        <Grid item>{text}</Grid>
        {subtitle && <Grid item>{subtitle}</Grid>}
      </PaymentMethodTextContainer>
    </PaymentMethodLayoutContainer>
  );
}

export function ExistingPaymentMethod({ checked, paymentMethodDetails, readOnly, onSelect }: PaymentMethodProps) {
  if (!paymentMethodDetails) {
    return null;
  }
  const { last4Digits, expirationMonth, expirationYear } = paymentMethodDetails;

  return (
    <PaymentMethodContainer item onClick={onSelect} $disabled={readOnly}>
      <PaymentMethodLayout
        checked={checked}
        readOnly={readOnly}
        text={<Typography variant="h6">{`Card ending in ${last4Digits}`}</Typography>}
        subtitle={
          !!expirationMonth &&
          !!expirationYear && (
            <Typography variant="body1">{`Expires ${expirationMonth
              .toString()
              .padStart(2, '0')}/${expirationYear}`}</Typography>
          )
        }
      />
    </PaymentMethodContainer>
  );
}

export function NewPaymentMethod({
  hasExistingPaymentMethod,
  checked,
  onSelect,
  readOnly,
  checkoutLocalization,
  onBillingAddressChange,
  collectPhoneNumber,
}: NewPaymentMethodProps) {
  return (
    <NewPaymentMethodContainer item $hideBorders={!hasExistingPaymentMethod} onClick={onSelect} $disabled={readOnly}>
      {hasExistingPaymentMethod && (
        <PaymentMethodLayout
          checked={checked}
          readOnly={readOnly}
          text={<Typography variant="h6">{checkoutLocalization.newPaymentMethodText}</Typography>}
        />
      )}
      <Collapse in={checked}>
        <StripePaymentForm onBillingAddressChange={onBillingAddressChange} collectPhoneNumber={collectPhoneNumber} />
      </Collapse>
    </NewPaymentMethodContainer>
  );
}
