import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';
import { range } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { useIsScreenWiderThan } from '../../hooks/useIsScreenWiderThan';

export const Footer = styled.div`
  margin-top: 32px;
`;

export function CustomerUsageLoader() {
  const isMdScreen = useIsScreenWiderThan('md');
  return (
    <Grid container spacing={4} className="stigg-subscription-usage-skeleton-layout" sx={{ flexWrap: 'wrap' }}>
      {range(6).map((item) => (
        <Grid key={item} item xs={isMdScreen ? 4 : 12}>
          <Skeleton width={280} height={120} />
        </Grid>
      ))}
    </Grid>
  );
}
