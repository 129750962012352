import keyBy from 'lodash/keyBy';
import compact from 'lodash/compact';
import { BillableFeatureInput, BillingPeriod } from '@stigg/api-client-js/src/generated/sdk';
import { BillableFeature, BillingModel, Plan, Price, Subscription } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';
import { getTierByQuantity, hasTierWithUnitPrice } from '../../utils/priceTierUtils';
import { getValidPriceQuantity } from '../../utils/priceUtils';
import { hasAnnualPrices, hasMonthlyPrices } from '../../utils/planPrices';

export type PlanStepState = {
  billingPeriod: BillingPeriod;
  billableFeatures: BillableFeatureInput[];
  billingCountryCode?: string;
};

type GetPlanStepInitialStateProps = {
  preferredBillingPeriod?: BillingPeriod;
  plan?: Plan;
  activeSubscription?: Subscription | null;
  billingCountryCode?: string;
  preconfiguredBillableFeatures: BillableFeature[];
};

const getBillingPeriod = (billingPeriod: BillingPeriod, hasMonthlyPrices?: boolean, hasAnnualPrices?: boolean) => {
  if (billingPeriod === BillingPeriod.Monthly && hasMonthlyPrices) {
    return billingPeriod;
  }
  if (billingPeriod === BillingPeriod.Annually && hasAnnualPrices) {
    return billingPeriod;
  }
  return null;
};

const isInAdvanceCommitmentCharge = ({ pricingModel }: Price) => {
  return pricingModel === BillingModel.PerUnit;
};

function getBillableFeatures(
  preconfiguredBillableFeatures: BillableFeature[],
  planPrices?: Price[],
  activeSubscription?: Subscription | null,
): BillableFeature[] {
  if (!planPrices) return [];

  const preconfBillableFeaturesByFeatureId = keyBy(preconfiguredBillableFeatures, 'featureId');

  const quantityByFeatureId = keyBy(
    compact(
      activeSubscription?.prices?.map((charge) => {
        const { feature } = charge;
        if (!feature || !isInAdvanceCommitmentCharge(charge)) {
          return null;
        }

        return {
          featureId: feature.featureId,
          quantity: feature.unitQuantity || null,
        };
      }),
    ),
    'featureId',
  );

  return compact(
    planPrices?.map((price) => {
      const featureId = price.feature?.featureId;
      if (!featureId || !isInAdvanceCommitmentCharge(price)) {
        return null;
      }

      let quantity;
      const preconfiguredQuantity =
        preconfBillableFeaturesByFeatureId[featureId]?.quantity || quantityByFeatureId[featureId]?.quantity;

      quantity = getValidPriceQuantity(price, preconfiguredQuantity || 1);

      if (price.isTieredPrice && !hasTierWithUnitPrice(price.tiers)) {
        const tier = getTierByQuantity(price.tiers!, quantity);
        quantity = tier.upTo!;
      }

      return {
        featureId,
        quantity,
      };
    }),
  );
}

function resolveBillingPeriod({
  plan,
  activeSubscription,
  preferredBillingPeriod,
}: {
  plan?: Plan;
  activeSubscription?: Subscription | null;
  preferredBillingPeriod?: BillingPeriod;
}) {
  const hasMonthlyPlan = hasMonthlyPrices(plan);
  const hasAnnualPlan = hasAnnualPrices(plan);

  if (preferredBillingPeriod) {
    const billingPeriod = getBillingPeriod(preferredBillingPeriod, hasMonthlyPlan, hasAnnualPlan);
    if (billingPeriod) {
      return billingPeriod;
    }
  }

  const isUpdate = activeSubscription?.plan?.id === plan?.id;
  if (isUpdate) {
    return activeSubscription?.price?.billingPeriod || BillingPeriod.Monthly;
  }

  if (activeSubscription?.prices && activeSubscription?.prices.length > 0) {
    const billingPeriod = getBillingPeriod(activeSubscription?.prices[0].billingPeriod, hasMonthlyPlan, hasAnnualPlan);
    if (billingPeriod) {
      return billingPeriod;
    }
  }

  return hasAnnualPlan ? BillingPeriod.Annually : BillingPeriod.Monthly;
}

export function getPlanStepInitialState({
  preferredBillingPeriod,
  plan,
  activeSubscription,
  billingCountryCode,
  preconfiguredBillableFeatures,
}: GetPlanStepInitialStateProps): PlanStepState {
  const billingPeriod = resolveBillingPeriod({ plan, activeSubscription, preferredBillingPeriod });
  const planPrices = plan?.pricePoints.filter((pricePoint) => pricePoint.billingPeriod === billingPeriod);
  const billableFeatures = getBillableFeatures(preconfiguredBillableFeatures, planPrices, activeSubscription);

  return {
    billingPeriod,
    billableFeatures,
    billingCountryCode,
  };
}

function usePlanState() {
  const [{ planStep }] = useCheckoutContext();
  return planStep;
}

function useSetBillableFeature() {
  const [, setState] = useCheckoutContext();

  return (featureId: string, quantity: number) =>
    setState((draft) => {
      const billableFeature = draft.planStep.billableFeatures.find(
        (billableFeature) => billableFeature.featureId === featureId,
      );

      if (billableFeature) {
        billableFeature.quantity = quantity;
      } else {
        draft.planStep.billableFeatures.push({ featureId, quantity });
      }
    });
}

function useSetBillingPeriod() {
  const [, setState] = useCheckoutContext();

  return (billingPeriod: BillingPeriod) =>
    setState((draft) => {
      draft.planStep.billingPeriod = billingPeriod;
    });
}

export function usePlanStepModel() {
  const state = usePlanState();

  return {
    ...state,
    setBillingPeriod: useSetBillingPeriod(),
    setBillableFeature: useSetBillableFeature(),
  };
}
