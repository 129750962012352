import React from 'react';
import { CustomerPortalSubscription, PricingType, SubscriptionStatus } from '@stigg/js-client-sdk';
import { SubscriptionsOverviewHeader } from './SubscriptionsOverviewHeader';
import { SubscriptionTabs } from './tabs/SubscriptionTabs';
import { useCustomerPortalContext } from '../CustomerPortalProvider';
import { SubscriptionOverviewLoader } from './SubscriptionOverviewLoader';
import { CustomerPortalSection } from '../CustomerPortal';
import { SubscriptionScheduledUpdatesAlert } from './subscriptionScheduledUpdates/SubscriptionScheduledUpdatesAlert';
import { SectionContainer } from '../common/SectionContainer';
import { UpcomingBilling } from './upcomingBilling/UpcomingBilling';
import { SubscriptionView } from './subscriptionView/SubscriptionView';
import { ChargeList } from './charges/ChargeList';
import { OnBuyMoreCallbackFn } from '../types';
import { OnManageClick } from '../CustomerPortalContainer';

export type SubscriptionsOverviewProps = {
  onManageSubscription: OnManageClick;
  onBuyMore?: OnBuyMoreCallbackFn;
  onCancelScheduledUpdates?: (subscription: CustomerPortalSubscription) => void;
  onContactSupport?: () => void;
  hiddenSections?: CustomerPortalSection[];
  cancelScheduledUpdatesButtonTitle: string;
};

export function SubscriptionsOverview({
  onManageSubscription,
  onBuyMore,
  onCancelScheduledUpdates,
  onContactSupport,
  hiddenSections,
  cancelScheduledUpdatesButtonTitle,
}: SubscriptionsOverviewProps) {
  const { customerPortal, isLoading, textOverrides, theme } = useCustomerPortalContext();
  const isLoadingData = isLoading || !customerPortal;
  const { subscriptions, entitlements, canUpgradeSubscription } = customerPortal || {};

  const activeSubscription = subscriptions?.find(({ status }) => status === SubscriptionStatus.Active);
  const trialSubscription = subscriptions?.find(({ status }) => status === SubscriptionStatus.InTrial);
  const hasCustomSubscription = activeSubscription?.pricingType === PricingType.Custom;
  const mainSubscription = activeSubscription || trialSubscription;

  return (
    <SectionContainer
      className="stigg-customer-portal-subscriptions-overview"
      $backgroundColor={theme.backgroundColor}
      $borderColor={theme.borderColor}>
      {!isLoadingData && activeSubscription && activeSubscription.scheduledUpdates && (
        <SubscriptionScheduledUpdatesAlert
          theme={theme}
          subscription={activeSubscription}
          onCancelScheduledUpdates={onCancelScheduledUpdates}
          cancelScheduledUpdatesButtonTitle={cancelScheduledUpdatesButtonTitle}
        />
      )}

      {isLoadingData || !mainSubscription ? (
        <SubscriptionOverviewLoader />
      ) : (
        <div
          className="stigg-overview-layout"
          style={{ display: 'flex', alignItems: 'flex-start', gap: 64, flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <SubscriptionsOverviewHeader
              onManageSubscription={onManageSubscription}
              hideManageButton={hasCustomSubscription}
              textOverrides={textOverrides}
            />

            <SubscriptionView
              mainSubscription={mainSubscription}
              trialSubscription={trialSubscription}
              theme={theme}
              onManageSubscription={onManageSubscription}
            />

            {activeSubscription && entitlements && (
              <ChargeList
                subscription={activeSubscription}
                entitlements={entitlements}
                onBuyMore={onBuyMore}
                onManageSubscription={onManageSubscription}
                canUpgradeSubscription={!!canUpgradeSubscription}
                hasCustomSubscription={hasCustomSubscription}
              />
            )}

            <SubscriptionTabs
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              customerPortal={customerPortal!}
              hiddenSections={hiddenSections}
              textOverrides={textOverrides}
              theme={theme}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: 319, gap: 24 }}>
            <UpcomingBilling
              subscription={mainSubscription}
              theme={theme}
              onContactSupport={onContactSupport}
              textOverrides={textOverrides}
            />
          </div>
        </div>
      )}
    </SectionContainer>
  );
}
