export const breakpoints = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
} as const;

export type Breakpoints = typeof breakpoints;

export type Breakpoint = keyof Breakpoints;

export const mqMinWidth = (breakpoint: Breakpoint) => `(min-width: ${breakpoints[breakpoint]}px)`;

export const mq = Object.keys(breakpoints).reduce(
  (obj, breakpoint) => ({ ...obj, [breakpoint]: `@media ${mqMinWidth(breakpoint as Breakpoint)}` }),
  {} as Record<Breakpoint, string>,
);
