import React from 'react';
import { CheckoutProvider, CheckoutProviderProps } from './CheckoutProvider';
import { CheckoutContainer, CheckoutContainerProps } from './CheckoutContainer';
import { CheckoutMockProps } from './types';

export type CheckoutProps = CheckoutProviderProps & CheckoutContainerProps & CheckoutMockProps;

export const Checkout = ({
  textOverrides,
  theme,
  resourceId,
  planId,
  preferredBillingPeriod,
  billingCountryCode,
  billableFeatures,
  billingInformation,
  onMockCheckoutState,
  skipCheckoutSteps,
  ...containerProps
}: CheckoutProps) => {
  return (
    <CheckoutProvider
      textOverrides={textOverrides}
      theme={theme}
      resourceId={resourceId}
      planId={planId}
      preferredBillingPeriod={preferredBillingPeriod}
      billingCountryCode={billingCountryCode}
      billableFeatures={billableFeatures}
      billingInformation={billingInformation}
      onMockCheckoutState={onMockCheckoutState}
      skipCheckoutSteps={skipCheckoutSteps}>
      <CheckoutContainer {...containerProps} />
    </CheckoutProvider>
  );
};
