import React from 'react';
import styled from '@emotion/styled/macro';
import { EntitlementResetPeriod } from '@stigg/js-client-sdk';
import CheckUrl from '../../assets/check-stigg.svg';
import { calculateUnitQuantityText } from './utils/calculateUnitQuantityText';
import { formatNumber } from '../utils/formatNumber';
import { Typography } from '../common/Typography';

const EntitlementName = styled(Typography)`
  margin: 0;
`;

const EntitlementRowContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const EntitlementIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: ${({ theme }) => theme.stigg.typography.h3.fontSize};
  flex-shrink: 0;
`;

const EntitlementCheckIcon = styled(CheckUrl)`
  flex-shrink: 0;
  * {
    fill: ${({ theme }) => theme.stigg.palette.text.disabled};
  }
`;

type EntitlementRowProps = {
  hasUnlimitedUsage?: boolean | null;
  isCustom?: boolean | null;
  usageLimit?: number | null;
  minUnitQuantity?: number | null;
  maxUnitQuantity?: number | null;
  resetPeriod?: EntitlementResetPeriod | null;
  displayNameOverride?: string | null;
  feature?: {
    units?: string | null | undefined;
    unitsPlural?: string | null | undefined;
    unitQuantity?: number | null;
    displayName: string;
  } | null;
};

function getResetPeriodText(resetPeriod?: EntitlementResetPeriod) {
  if (!resetPeriod) {
    return '';
  }

  switch (resetPeriod) {
    case EntitlementResetPeriod.Day: {
      return 'per day';
    }
    case EntitlementResetPeriod.Hour: {
      return 'per hour';
    }
    case EntitlementResetPeriod.Week: {
      return 'per week';
    }
    case EntitlementResetPeriod.Month: {
      return 'per month';
    }
    case EntitlementResetPeriod.Year: {
      return 'per year';
    }
    default: {
      return '';
    }
  }
}

function getEntitlementDisplay({
  hasUnlimitedUsage,
  feature,
  resetPeriod,
  usageLimit,
  minUnitQuantity,
  maxUnitQuantity,
  isCustom,
}: EntitlementRowProps) {
  const unitBasedEntitlement = minUnitQuantity || maxUnitQuantity;
  const resetPeriodSuffix = `${resetPeriod && usageLimit ? `${getResetPeriodText(resetPeriod)}` : ''}`;

  if (hasUnlimitedUsage) {
    return `Unlimited ${feature?.unitsPlural}`;
  }

  if (isCustom) {
    return `Custom ${feature?.unitsPlural}`;
  }

  if (usageLimit) {
    const featureUnits = usageLimit === 1 ? feature?.units : feature?.unitsPlural;
    return `${formatNumber(usageLimit)} ${featureUnits} ${resetPeriodSuffix}`;
  }

  if (unitBasedEntitlement) {
    return calculateUnitQuantityText(minUnitQuantity, maxUnitQuantity, feature?.unitsPlural);
  }

  return feature?.displayName;
}

export function EntitlementRow(props: EntitlementRowProps) {
  const { displayNameOverride, ...restProps } = props;
  const displayName = getEntitlementDisplay(restProps);

  return (
    <EntitlementRowContainer className="stigg-entitlement-row-container">
      <EntitlementIconContainer>
        <EntitlementCheckIcon className="stigg-entitlement-row-icon" />
      </EntitlementIconContainer>
      <EntitlementName className="stigg-entitlement-name" variant="h6" color="secondary">
        {displayNameOverride || displayName}
      </EntitlementName>
    </EntitlementRowContainer>
  );
}
