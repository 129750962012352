import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';

import { Button } from '../../components';
import { mq } from '../../../common/mediaQuery';

export const CheckoutAddonsContainer = styled(Grid)`
  width: 100%;
  gap: 8px;
`;

export const AddonListItemContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mq.md} {
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    align-items: center;
  }
`;

export const TrashButton = styled(Button)`
  min-width: unset;
  width: 41px;
  height: 41px;
  padding: 0;
`;
