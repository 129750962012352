import { PriceTierFragment } from '@stigg/js-client-sdk';
import styled from '@emotion/styled/macro';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { map } from 'lodash';
import React from 'react';
import { Typography } from './Typography';
import { TiersSelectContainerProps } from './TiersSelectContainer';
import { formatNumber } from '../utils/formatNumber';

const TierSelect = styled(Select)`
  border-radius: 10px;
  min-height: 42px;
  min-width: 120px;

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.stigg.palette.outlinedBorder};
  }
`;

const TierInput = styled(OutlinedInput)`
  & .MuiInputBase-input {
    padding: 10px 12px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.stigg.palette.primary} !important;
  }
`;

export function VolumeBulkSelect({
  value,
  componentId,
  tierUnits,
  tiers,
  handleChange,
}: Pick<TiersSelectContainerProps, 'value' | 'componentId' | 'tiers' | 'tierUnits'> & {
  handleChange: (quantity: number) => void;
}) {
  if (!tiers) {
    return null;
  }

  return (
    <TierSelect
      value={value.toString()}
      fullWidth
      onChange={(event) => {
        const quantity = event?.target?.value ? Number(event?.target?.value) : null;
        if (quantity) {
          handleChange(quantity);
        }
      }}
      id={componentId}
      input={<TierInput />}
      MenuProps={{
        MenuListProps: { disablePadding: true },
        PaperProps: {
          sx: { marginTop: '4px', borderRadius: '10px' },
        },
      }}>
      {map(tiers, (tier: PriceTierFragment) => (
        <MenuItem className="stigg-price-tier-menu-item-text" key={tier.upTo} value={tier.upTo?.toString()}>
          <Typography variant="body1" color="primary" style={{ lineHeight: 'unset' }}>
            {formatNumber(tier.upTo)} {tierUnits}
          </Typography>
        </MenuItem>
      ))}
    </TierSelect>
  );
}
